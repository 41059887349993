(function() {
  (function($) {
    var addClusterCSS, addMarkerLayer, divIcon, showInfo;
    // as the page loads, call these scripts
    $(document).ready(function($) {
      var URL, closeNav, openNav;
      
      // nav
      openNav = function() {
        $('#header').toggleClass('activated');
        $('.navHamburger').toggleClass('activated');
        return $('body').toggleClass('activated stuck');
      };
      closeNav = function() {
        $('#header').removeClass('activated');
        return $('body').removeClass('activated stuck');
      };
      $('#header').find('.navHamburger').on('click focus', function(event) {
        event.stopPropagation();
        openNav();
        $('#container').on('click', function(e) {
          if ($(e.target).parents('.header--navigation').length === 0) {
            closeNav();
            return $(this).off('click');
          }
        });
        return $(document).on('keyup', function(e) {
          if (e.keyCode === 27) { // escape key maps to keycode `27`
            closeNav();
            return $(this).off('keyup');
          }
        });
      });
      $('.post-type-archive-event #category-select select').on('change', function() {
        return window.location = '/events/category/' + $(this).val() + '/';
      });
      URL = '1poN-_GeN6brTVnIYvDjnF7R5796WzUeSkGgNwqzsi68';
      return Tabletop.init({
        key: URL,
        callback: showInfo,
        simpleSheet: true
      });
    });
    showInfo = function(data) {
      var attribution, filteredData, i, j, len, location, map, mapOptions, markerLayer, popup, primaryCulturalDomain, tiles;
      popup = '<div class="block--space block--space_{{#primary_va}}va{{/primary_va}}{{#primary_sr}}sr{{/primary_sr}}{{#primary_lp}}lp{{/primary_lp}}{{#primary_ai}}ai{{/primary_ai}}{{#primary_hr}}hr{{/primary_hr}}{{#primary_wp}}wp{{/primary_wp}}"> <div class="block--space_details"> <h2>{{Organization}}<svg class="block--space_pin" width="9" height="17" viewBox="0 0 31 53" xmlns="http://www.w3.org/2000/svg" class="icon--map"><path d="M15.158 52.479C5.053 33.179 0 20.739 0 15.158 0 6.786 6.786 0 15.158 0c8.371 0 15.158 6.786 15.158 15.158 0 5.58-5.053 18.021-15.158 37.321zm-.28-32.269a5.895 5.895 0 1 0 0-11.789 5.895 5.895 0 0 0 0 11.79z" fill-rule="evenodd"/></svg></h2> <h3>{{Space Name}}</h3> <p class="space_address"> {{Address}}<br/> {{City}}, {{Province}}, {{Postal Code}} </p> {{#Web Address}}<p class="space_website"><a href="{{Web Address}}" target="_blank">Visit website</a></p>{{/Web Address}} {{#Telephone}}<p class="space_phone"><a href="tel:{{Telephone}}">{{Telephone}}</a></p>{{/Telephone}} {{#SpaceFinder Link}}<p class="space_book"><a href="{{SpaceFinder Link}}" target="_blank">View on SpaceFinder</a></p>{{/SpaceFinder Link}} </div> </div>';
      filteredData = data.filter((location) => {
        return location["lat"] !== "" && location["Status"] === "Active" && location["Primary Culture Domain"].indexOf("Education and Training") === -1 && location["Primary Culture Domain"].indexOf("Professional Support") === -1 && location["Primary Culture Domain"].indexOf("Heritage and Libraries") === -1;
      });
      for (i = j = 0, len = filteredData.length; j < len; i = ++j) {
        location = filteredData[i];
        primaryCulturalDomain = location["Primary Culture Domain"];
        filteredData[i]["long"] = location["lng"];
        filteredData[i]["hexcolor"] = (function() {
          switch (false) {
            case primaryCulturalDomain !== 'Visual and Applied Arts':
              return '#00a6b5'; // $color-blue
            case primaryCulturalDomain !== 'Audiovisual and Interactive Media':
              return '#E32726'; // $color-red
            case primaryCulturalDomain !== 'Live Performance':
              return '#F5B120'; // $color-yellow
            case primaryCulturalDomain !== 'Sound Recording':
              return '#bd2f2a'; // $color-red-dk
            case primaryCulturalDomain !== 'Heritage and Libraries':
              return '#8e79af'; // $color-purple
            case primaryCulturalDomain !== 'Written and Published Works':
              return '#76c3d4'; // $color-blue-lt
          }
        })();
        switch (false) {
          case primaryCulturalDomain !== 'Visual and Applied Arts':
            filteredData[i]["primary_va"] = true;
            break;
          case primaryCulturalDomain !== 'Audiovisual and Interactive Media':
            filteredData[i]["primary_ai"] = true;
            break;
          case primaryCulturalDomain !== 'Live Performance':
            filteredData[i]["primary_lp"] = true;
            break;
          case primaryCulturalDomain !== 'Sound Recording':
            filteredData[i]["primary_sr"] = true;
            break;
          case primaryCulturalDomain !== 'Heritage and Libraries':
            filteredData[i]["primary_hr"] = true;
            break;
          case primaryCulturalDomain !== 'Written and Published Works':
            filteredData[i]["primary_wp"] = true;
        }
      }
      mapOptions = {
        data: filteredData,
        mapDiv: 'map',
        geoJSONincludes: ['Status', 'Primary Culture Domain', 'Secondary/Interdisciplinary Culture Domain', 'Space Notes', 'Space Name', 'Organization', 'Address', 'City', 'Province', 'Postal Code', 'Web Address', 'SpaceFinder Link', 'Telephone', 'primary_va', 'primary_ai', 'primary_lp', 'primary_sr', 'primary_hr', 'primary_wp'],
        cluster: true,
        template: popup
      };
      map = L.map(mapOptions.mapDiv, {
        maxZoom: 18
      });
      tiles = mapOptions.tiles || 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
      attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
      L.tileLayer(tiles, {
        attribution: attribution
      }).addTo(map);
      // Set behavior
      map.touchZoom.disable();
      // map.doubleClickZoom.disable()
      map.scrollWheelZoom.disable();
      markerLayer = addMarkerLayer(map, mapOptions);
      
      // $('#map--wrapper #cultural_domain, #map--wrapper #status').on 'change', ->
      $('#map--wrapper #cultural_domain').on('change', function() {
        map.removeLayer(markerLayer);
        $('.block--map_list').empty();
        return markerLayer = addMarkerLayer(map, mapOptions);
      });
      return $('#map__search-form').on('submit', function(event) {
        event.preventDefault();
        map.removeLayer(markerLayer);
        $('.block--map_list').empty();
        markerLayer = addMarkerLayer(map, mapOptions);
        $('#map__search-clear').removeClass('hidden');
        return $('#map__search-clear a').on('click', function(event) {
          event.preventDefault();
          $('#map__search-form #map__search-input').val('');
          map.removeLayer(markerLayer);
          $('.block--map_list').empty();
          markerLayer = addMarkerLayer(map, mapOptions);
          $('#map__search-clear').addClass('hidden');
          return $('#map__search-clear a').off('click');
        });
      });
    };
    addMarkerLayer = function(map, mapOpts) {
      var clusterGroup, features, geoJSON, iconColor, layer, markers;
      
      // // setting a color in options overides colors in spreadsheet
      if (mapOpts.hexcolor) {
        iconColor = mapOpts.hexcolor;
      }
      mapOpts.geoJSONincludes = mapOpts.geoJSONincludes || null; // um?
      geoJSON = Sheetsee.createGeoJSON(mapOpts.data, mapOpts.geoJSONincludes);
      if (!mapOpts.template) {
        mapOpts.template = makePopupTemplate(geoJSON);
      }
      features = {
        'type': 'FeatureCollection',
        'features': geoJSON
      };
      if (mapOpts.cluster) {
        clusterGroup = new L.MarkerClusterGroup();
      }
      
      // layer = L.geoJson(features)
      layer = L.geoJson(features, {
        filter: function(feature) {
          var match, searchValue;
          if ($('#map--wrapper #cultural_domain').val() !== "" || $("#map__search-input").val() !== "") {
            match = true;
            if ($('#map--wrapper #cultural_domain').val() !== "") {
              match = match && feature.opts["Primary Culture Domain"] === $('#map--wrapper #cultural_domain').val();
            }
            if ($("#map__search-input").val() !== "") {
              searchValue = $("#map__search-input").val().toLowerCase();
              match = match && (feature.opts["Organization"].toLowerCase().indexOf(searchValue) !== -1 || feature.opts["Space Name"].toLowerCase().indexOf(searchValue) !== -1 || feature.opts["Address"].toLowerCase().indexOf(searchValue) !== -1 || feature.opts["City"].toLowerCase().indexOf(searchValue) !== -1);
            }
            return match;
          } else {
            return true;
          }
        }
      });
      markers = new Array();
      layer.eachLayer(function(marker) {
        var popupContent;
        popupContent = Mustache.render(mapOpts.template, marker.feature.opts);
        marker.bindPopup(popupContent, {
          closeButton: false
        });
        marker.setIcon(divIcon(iconColor || marker.feature.properties.color));
        marker.on('click', function() {
          return $(".block--map_list").scrollTo('#item-' + marker._leaflet_id);
        });
        if (mapOpts.cluster) {
          clusterGroup.addLayer(marker);
        }
        markers[marker._leaflet_id] = marker;
        $('.block--map_list').append('<div class="item" id="item-' + marker._leaflet_id + '" data-item-id="' + marker._leaflet_id + '">' + popupContent + '</div>');
        return $('.item').on("click", function() {
          var LatLng, mid;
          $('.item').removeClass('active');
          $(this).addClass('active');
          mid = $(this).data('item-id');
          LatLng = markers[mid].getLatLng();
          return map.flyTo(LatLng, 16).once('moveend', function() {
            return clusterGroup.zoomToShowLayer(markers[mid], function() {
              return markers[mid].openPopup();
            });
          });
        });
      });
      map.fitBounds(layer.getBounds());
      if (mapOpts.cluster) {
        map.addLayer(clusterGroup);
        addClusterCSS(iconColor || '#2196f3');
        return clusterGroup;
      } else {
        layer.addTo(map);
        return layer;
      }
    };
    divIcon = function(color) {
      var icon, markerHtmlStyles;
      markerHtmlStyles = 'width: 2rem; display: block; left: -1rem; top: -1rem; position: relative;';
      icon = L.divIcon({
        className: 'div-icon',
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: '<svg width="31" height="53" viewBox="0 0 31 53" xmlns="http://www.w3.org/2000/svg" class="icon--map" style="' + markerHtmlStyles + '"><path d="M15.158 52.479C5.053 33.179 0 20.739 0 15.158 0 6.786 6.786 0 15.158 0c8.371 0 15.158 6.786 15.158 15.158 0 5.58-5.053 18.021-15.158 37.321zm-.28-32.269a5.895 5.895 0 1 0 0-11.789 5.895 5.895 0 0 0 0 11.79z" fill="' + color + '" fill-rule="evenodd"/></svg>'
      });
      return icon;
    };
    return addClusterCSS = function(color) {
      var css, style;
      if (!color.match('#')) {
        color += '#';
      }
      css = '.marker-cluster-small, .marker-cluster-small div, .marker-cluster-medium,' + '.marker-cluster-medium div, .marker-cluster-large, .marker-cluster-large div' + '{background-color:' + color + ';} .marker-cluster {background-clip: padding-box; border-radius: 20px;}' + '.marker-cluster div {width: 30px; height: 30px; margin-left: 5px; margin-top: 5px;' + 'text-align: center; border-radius: 15px; font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;}' + '.marker-cluster span {line-height: 30px;}';
      style = document.createElement('style');
      style.innerHTML = css;
      return document.head.appendChild(style);
    };
  })(jQuery);

}).call(this);
